import React, { useEffect, useState } from 'react'
import SearchInput from '../../../components/inputs/SearchInput'
import CheckBoxInput from '../../../components/inputs/CheckBoxInput'
import EmpresaService from '../../../services/cadastro/EmpresaService'
import { getEmpresaDTO } from '../../../dtos/cadastro/EmpresaDTO'
import { formatCnpj } from '../../../helpers/formaters'

const DadosDespachanteEmp = ({ form, edicaoHabilitada }) => {
  const [sugestaoDespachantes, setSugestaoDespachantes] = useState([])

  useEffect(async () => {
    if (form.values.coddespEmp) {
      const empresaService = new EmpresaService()

      const despachante = await empresaService.get(form.values.coddespEmp)

      form.setFieldValue('despachante', despachante)
    }
  }, [])

  async function sugerirDespachantes () {
    try {
      const empresaService = new EmpresaService()

      const despachantes = (await empresaService.filter(`apenasDespachantes=true&ignorarGrupo=true&descricaoFiltro=${form.values.despachante.nomfan_emp}`)).data

      setSugestaoDespachantes(despachantes)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um despachante!')
    }
  }

  function handleChangeDespachante (event) {
    form.setFieldValue('despachante', { nomfan_emp: event.target.value })
  }

  return (
    <div className="formgrid grid">
      <div className="field col-12">
        <SearchInput
          //AutoComplete
          label="Despachante"
          field="nomfan_emp"
          value={form.values.despachante?.nomfan_emp && form.values.despachante}
          suggestions={sugestaoDespachantes}
          completeMethod={sugerirDespachantes}
          onChange={handleChangeDespachante}
          onSelect={(e) => form.setFieldValue('despachante', e.value)}
          className="inputfield w-full"
          disabled={!edicaoHabilitada}
          //FiltroModal
          filtroTitle="Pesquisa de despachantes"
          filtersDefault={{ apenasDespachantes: true, ignorarGrupo: true }}
          service={EmpresaService}
          model={getEmpresaDTO}
          columns={[
            { campo: 'nomfan_emp', nome: 'Nome' },
            { campo: 'cnpj_emp', nome: 'CNPJ', format: formatCnpj }
          ]}
        />
      </div>
      <div className="field col-12 md:col-6">
        <CheckBoxInput
          label="Permitir despachante realizar operações para veículos novos?"
          value={form.values.perNovDespEmp}
          checked={form.values.perNovDespEmp}
          onChange={() => form.setFieldValue('perNovDespEmp', !form.values.perNovDespEmp)}
          disabled={!edicaoHabilitada}
        />
      </div>
      <div className="field col-12 md:col-6">
        <CheckBoxInput
          label="Permitir despachante realizar operações para veículos usados?"
          value={form.values.perUsaDespEmp}
          checked={form.values.perUsaDespEmp}
          onChange={() => form.setFieldValue('perUsaDespEmp', !form.values.perUsaDespEmp)}
          disabled={!edicaoHabilitada}
        />
      </div>
    </div>
  )
}

export default DadosDespachanteEmp
