import List from '../../../classes/List'
import React from 'react'
import Container from '../../../components/layout/Container'
import DataList from '../../../components/utils/DataList'
import Page from '../../../components/layout/Page'
import { Column } from 'primereact/column'
import { getEstoqueUsadosDTO } from '../../../dtos/veiculosUsados/EstoqueUsadosDTO'
import EstoqueUsadosService from '../../../services/veiculosUsados/EstoqueUsadosService'
import classNames from 'classnames'
import CommonHelper from '../../../helpers/CommonHelper'
import ResponsiveColumn from '../../../components/utils/ResponsiveColumn'
import { Button } from 'primereact/button'
import { formatDescricaoVeiculo } from '../../../helpers/formaters'
import DropdownMenu from '../../../components/inputs/DropdownButton/DropdownMenu'
import AssinarAtpveModal from '../entradaVeiculoUsado/AssinarAtpveModal'
import EnviarNotaFiscalModal from '../transferencia/cliente/EnviarNotaFiscalModal'
import Confirm from '../../../components/utils/Confirm'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../../../components/utils/Message'
import SolicitacaoEntradaService from '../../../services/veiculosUsados/SolicitacaoEntradaService'
import ImpressoesUsadosService from '../../../services/veiculosUsados/ImpressoesUsadosService'
import AtpvService from '../../../services/atpv/AtpvService'
import SolicitacaoSaidaService from '../../../services/veiculosUsados/SolicitacaoSaidaService'
import AutorizacaoTransferenciaService from '../../../services/veiculosUsados/AutorizacaoTransferenciaService'
import CancelarAutorizacaoTransfrenciaModal from '../transferencia/estabelecimento/CancelarAutorizacaoTransfrenciaModal'
import VeiculoModal from '../../../forms/cadastro/veiculo/VeiculoModal'
import Modal from '../../../components/utils/Modal'
import TextInput from '../../../components/inputs/TextInput'
import CheckBoxInput from '../../../components/inputs/CheckBoxInput'

class EstoqueUsados extends List {
  constructor (props) {
    super(
      props,
      getEstoqueUsadosDTO,
      EstoqueUsadosService,
      '',
      '',
      'confirmadosESolicitados'
    )

    this.state = {
      ...this.state,
      visibleAssinarAtpveModal: false,
      visibleEnviarNotaFiscalModal: false,
      visibleCancelarAutorizacaoTransferenciaModal: false,
      visibleVeiculoModal: false,
      codigoVeiculo: null,
      confirmTitle: '',
      sufixoPlacaMock: '',
      cpfProprietarioMock: '',
      nomeProprietarioMock: '',
      veiculoRouboFurtoMock: false,
      veiculoBaixadoMock: false,
      ufJurisdicaoMock: '',
      confirmDescription: '',
      onConfirm: null,
      veiculoSelecionado: {},
      visibleCriarVeiculoMockModal: false,
      autorizacaoTransferenciaVeiculo: null
    }

    this.setColumns([
      {
        key: 'id',
        component: (
          <Column
            style={{ width: '15%' }}
            header="Id Estoque"
            field="id"
            sortable
            headerClassName="id"
          />
        )
      },
      {
        key: 'placa',
        component: (
          <Column
            style={{ width: '35%' }}
            header="Veiculo"
            body={data => formatDescricaoVeiculo(data.placa, data.descricaoMarcaModelo)}
            field="placa"
            sortable
            headerClassName="placa"
          />
        )
      },
      {
        key: 'entradaEstoque.dataHora',
        component: (
          <Column
            style={{ width: '15%' }}
            header="Data Entrada"
            body={data => CommonHelper.dateToBrazilian(data.entradaEstoque.dataHora)}
            field="entradaEstoque.dataHora"
            sortable
            headerClassName="entradaEstoque.dataHora"
          />
        )
      },
      {
        key: 'saidaEstoque.dataHora',
        component: (
          <Column
            style={{ width: '15%' }}
            header="Data Saída"
            body={data => CommonHelper.dateToBrazilian(data.saidaEstoque?.dataHora)}
            field="saidaEstoque.dataHora"
            sortable
            headerClassName="saidaEstoque.dataHora"
          />
        )
      },
      {
        key: 'restricoesVeiculo',
        component: (
          <Column
            style={{ width: '10%' }}
            body={data => data.restricoesVeiculo ? 'Sim' : 'Não'}
            header="Restrições"
            field="restricoesVeiculo"
            sortable
            headerClassName="restricoesVeiculo"
          />
        )
      },
      {
        key: 'estado',
        component: (
          <Column
            style={{ width: '10%' }}
            header="Situação"
            body={data => {
              let descricaoSituacao = ''
              let descricaoColor = ''

              switch (data.estado) {
              case 'CONFIRMADO':
                descricaoSituacao = 'Estoque renave'
                descricaoColor = 'green'
                break
              case 'SOLICITADO':
                descricaoSituacao = 'Entrada solicitada'
                descricaoColor = 'light-green'
                break
              case 'FINALIZADO':
                descricaoSituacao = 'Processo concluído'
                descricaoColor = 'blue'
                break
              }

              return <ResponsiveColumn
                column="Situação"
                value={descricaoSituacao}
                className={`colored-label ${descricaoColor}`}
              />
            }}
            field="estado"
            sortable
            headerClassName="estado"
          />
        )
      }
    ])
  }

  async cancelarEntrada () {
    try {
      await SolicitacaoEntradaService.cancelarEntrada(this.state.veiculoSelecionado)

      this.setState({ visibleConfirm: false })

      await this.onFilter()

      showSuccessMessage('Entrada cancelada com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar cancelar a entrada!')
    }
  }

  async cancelarSaida () {
    try {
      await SolicitacaoSaidaService.cancelarSaida(this.state.veiculoSelecionado.id)

      this.setState({ visibleConfirm: false })

      await this.onFilter()

      showSuccessMessage('Saída cancelada com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar cancelar a saída!')
    }
  }

  async downloadTermoEntrada () {
    try {
      const { id, placa } = this.state.veiculoSelecionado

      const pdfTermoEntradaBase64 = await ImpressoesUsadosService.getTermoEntrada(id)

      CommonHelper.downloadFile('data:application/pdf;base64,', pdfTermoEntradaBase64, `Termo-Entrada-${placa}.pdf`)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar fazer o download do termo de entrada!')
    }
  }

  async downloadTermoSaida () {
    try {
      const { id, placa } = this.state.veiculoSelecionado

      const pdfTermoSaidaBase64 = await ImpressoesUsadosService.getTermoSaida(id)

      CommonHelper.downloadFile('data:application/pdf;base64,', pdfTermoSaidaBase64, `Termo-Saida-${placa}.pdf`)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar fazer o download do termo de saída!')
    }
  }

  async downloadCrlv () {
    try {
      const { renavam, placa } = this.state.veiculoSelecionado

      const pdfCrlvBase64 = await ImpressoesUsadosService.getCrlv(placa, renavam)

      CommonHelper.downloadFile('data:application/pdf;base64,', pdfCrlvBase64, `CRLV-${placa}.pdf`)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar fazer o download do CRLV!')
    }
  }

  async downloadAtpve () {
    try {
      const { renavam, placa } = this.state.veiculoSelecionado

      const responseRenave = await AtpvService.getAtpvUsado(placa, renavam)

      const pfdAtpvBase64 = await AtpvService.atualizarAtpv(responseRenave.data.pdfAtpvBase64)

      CommonHelper.downloadFile('data:application/pdf;base64,', pfdAtpvBase64, `ATPV-${placa}.pdf`)
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao baixar o ATPV-e')
    }
  }

  async downloadUltimoCodSegurancaCRLVe () {
    try {
      const { renavam, placa } = this.state.veiculoSelecionado

      const pdfCodSegCrlvBase64 = await ImpressoesUsadosService.getUltimoCodSegurancaCRLVe(placa, renavam)

      CommonHelper.downloadFile('data:application/pdf;base64,', pdfCodSegCrlvBase64, `CodSeguranca-${placa}.pdf`)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar fazer o download do Codigo de segurança do CRLV!')
    }
  }

  async onRowClick (event) {
    try {
      const veiculoSelecionado = event.data

      const autorizacaoTransferenciaVeiculo = await AutorizacaoTransferenciaService.getAutorizacaoVeiculo(veiculoSelecionado.placa)

      this.setState({ veiculoSelecionado, autorizacaoTransferenciaVeiculo })
      this.handleRowExpansion(veiculoSelecionado)
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao verificar se o veículo possui alguma autorização de transferência')
    }
  }

  render () {
    const state = this.state

    const itensTransferir = [
      {
        label: 'Para cliente',
        command: () => this.props.history.push(`/transferir-veiculo-usado-para-cliente/${state.selected.placa}`)
      },
      {
        label: 'Para outra filial',
        command: () => this.props.history.push(`/transferir-veiculo-usado-para-filial/${state.selected.placa}`)
      },
      {
        label: 'Para outro estabelecimento',
        command: () => this.props.history.push(`/transferir-veiculo-usado-para-estabelecimento/${state.selected.placa}`)
      }
    ]

    const itensImpressao = [
      {
        label: 'Termo de entrada',
        command: () => this.downloadTermoEntrada()
      },
      {
        label: 'Termo de saída',
        command: () => this.downloadTermoSaida()
      },
      {
        label: 'CRLV-e',
        command: () => this.downloadCrlv()
      },
      {
        label: 'ATPV',
        command: () => this.downloadAtpve()
      },
      {
        label: 'Cód. Segurança CRLV-e',
        command: () => this.downloadUltimoCodSegurancaCRLVe()
      }
    ]

    const rowExpansionTemplate = (data) => {
      let descricaoRestricoes

      if (data.restricoesVeiculo) {
        const arrayDescricoesRestricoesVeiculo = data.restricoesVeiculo.map(restricao => restricao.tipoRestricao)

        if (arrayDescricoesRestricoesVeiculo.length > 1) {
          descricaoRestricoes = arrayDescricoesRestricoesVeiculo.join(', ') + '.'
        } else {
          descricaoRestricoes = arrayDescricoesRestricoesVeiculo[0] + '.'
        }
      }

      function isSolicitado () {
        return data.estado === 'SOLICITADO'
      }

      function isConfirmado () {
        return data.estado === 'CONFIRMADO'
      }
      function isFinalizado () {
        return data.estado === 'FINALIZADO'
      }

      function isNFPendente () {
        return !data.entradaEstoque?.chaveNotaFiscalEntrada || !data.saidaEstoque?.chaveNotaFiscalSaida
      }
      return (
        <>
          <div className="expansion row-expansion flex flex-column justify-content-start align-items-start">
            <label>
            Veículo:
              <span className="black underline cursor-pointer ml-1" onClick={() => {
                if (!data.codigoVeiculo) {
                  showWarnMessage('Não foi possível encontrar o veículo nos cadastros, por favor cadastre o veículo!')
                  return
                }

                this.setState({ codigoVeiculo: data.codigoVeiculo, visibleVeiculoModal: true })
              }}>
                {data.placa}
              </span>
            </label>
            <label>
            NF-e de entrada:
              <span className="ml-1">
                {data.entradaEstoque?.chaveNotaFiscalEntrada || 'Aguardando envio'}
              </span>
            </label>
            <label>
            NF-e de saída:
              <span className="ml-1">
                {data.saidaEstoque?.chaveNotaFiscalSaida || 'Aguardando envio'}
              </span>
            </label>
            <label>
            Restrições:
              <span className="ml-1">
                {descricaoRestricoes || 'Não há'}
              </span>
            </label>
          </div>
          <div className="row-expansion flex justify-content-end">
            {state.autorizacaoTransferenciaVeiculo && (
              <Button
                label="Cancelar autorização de transferência"
                className="mr-2"
                onClick={() => this.setState({ visibleCancelarAutorizacaoTransferenciaModal: true })}
              />
            )}
            {!isFinalizado() && (
              <Button
                label="Enviar ATPV-e assinado"
                className="mr-2"
                onClick={() => this.setState({ visibleAssinarAtpveModal: true })}
              />
            )}
            <div className="flex align-items-center">
              <DropdownMenu
                items={itensImpressao}
                className="mr-2 w-full"
                label="Impressões"
              />
            </div>
            {isNFPendente() && (
              <Button
                label="Enviar nota fiscal"
                className="mr-2"
                onClick={() => this.setState({ visibleEnviarNotaFiscalModal: true })}
              />
            )}
            {isSolicitado() && (
              <Button
                label="Cancelar entrada"
                className="mr-2"
                onClick={() => this.setState({
                  visibleConfirm: true,
                  confirmTitle: 'Confirmar cancelar a entrada?',
                  confirmDescription: 'Deseja realmente cancelar a entrada do veículo selecionado?',
                  onConfirm: this.cancelarEntrada.bind(this)
                })}
              />
            )}
            {isFinalizado() && (
              <Button
                label="Cancelar saída"
                className="mr-2"
                onClick={() => this.setState({
                  visibleConfirm: true,
                  confirmTitle: 'Confirmar cancelar a saída?',
                  confirmDescription: 'Deseja realmente cancelar a saída do veículo selecionado?',
                  onConfirm: this.cancelarSaida.bind(this)
                })}
              />
            )}
            {isConfirmado() && (
              <div className="flex align-items-center">
                <DropdownMenu
                  items={itensTransferir}
                  className="mr-5 w-full"
                  label="Transferir"
                />
              </div>
            )}
          </div>
        </>
      )
    }

    const onSelectOption = (option) => {
      let { filter } = this.state
      this.setState({ opcaoSelecionada: option })

      switch (option) {
      case 'confirmadosESolicitados':
        filter.situacaoVeiculos = 'confirmadosESolicitados'
        break
      case 'andamento':
        filter.situacaoVeiculos = 'andamento'
        break
      case 'finalizados':
        filter.situacaoVeiculos = 'finalizados'
        break
      case 'todos':
        filter.situacaoVeiculos = 'todos'
        break
      }

      this.setState({ filter }, this.onFilter)
    }

    const itensEntradaEstoque = [
      {
        label: 'Entrada de outro estabelecimento',
        command: () => this.props.history.push('/receber-transferencia-usados-estabelecimentos')
      },
      {
        label: 'Compra de veículo',
        command: () => this.props.history.push('/entrada-veiculo-usado/compra')
      },
      {
        label: 'Entrada de veículo próprio',
        command: () => this.props.history.push('/entrada-veiculo-usado/veiculo-proprio')
      }
    ]

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Estoque de veículos</h4>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: this.state.opcaoSelecionada === 'confirmadosESolicitados' }, 'filter-option')}
              onClick={() => onSelectOption('confirmadosESolicitados')}>
              <span className="option-label">Solicitados/Estoque Renave</span>
              {/* <div className="option-quantity">
                {state.quantidades.quantidadeConfirmadosESolicitados}
              </div> */}
            </div>
            <div className={classNames({ active: this.state.opcaoSelecionada === 'andamento' }, 'filter-option')}
              onClick={() => onSelectOption('andamento')}>
              <span className="option-label">Em Andamento</span>
              {/* <div className="option-quantity">
                {state.quantidades.quantidadeTransferenciaSaida}
              </div> */}
            </div>
            <div className={classNames({ active: this.state.opcaoSelecionada === 'finalizados' }, 'filter-option')}
              onClick={() => onSelectOption('finalizados')}>
              <span className="option-label">Finalizados</span>
              {/* <div className="option-quantity">
                {state.quantidades.quantidadeFinalizados}
              </div> */}
            </div>
            {/* <div className={classNames({ active: this.state.opcaoSelecionada === 'todos' }, 'filter-option')}
              onClick={() => onSelectOption('todos')}>
              <span className="option-label">Todos</span>
              <div className="option-quantity">
                {state.quantidades.quantidadeTodosOsRegistros}
              </div>
            </div> */}
          </div>
          <div className='flex'>
            {CommonHelper.isUsuarioAdmin() && (
              <div className="flex align-items-center">
                <Button
                  label="Criar veículo mock"
                  onClick={() => this.setState({ visibleCriarVeiculoMockModal: true })}
                  className="mr-2 w-full"
                />
              </div>
            )}
            <div className="flex align-items-center">
              <DropdownMenu
                items={itensEntradaEstoque}
                icon="pi pi-plus"
                className="mr-2 w-full"
                label="Entrada estoque"
              />
            </div>
          </div>
        </div>
        <Container>
          <DataList
            data={state.list}
            selected={state.selected}
            rows={state.rows}
            onSelect={this.onSelect}
            totalRecords={state.totalRecords}
            first={state.first}
            onPage={this.onPageChange}
            page={state.page}
            responsive
            showFilter
            filterPlaceholder="Procurar estoque usados"
            filterName="descricaoFiltro"
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
            onRowClick={(e) => this.onRowClick(e)}
            expandedRows={this.state.expandedRows}
            rowExpansionTemplate={rowExpansionTemplate}
            expandedFiltersTemplate={this.expandedFiltersTemplate}
          >
            {this.columns.map((column) => column.component)}
          </DataList>
        </Container>
        <AssinarAtpveModal
          visible={state.visibleAssinarAtpveModal}
          onHide={() => this.setState({ visibleAssinarAtpveModal: false })}
          veiculo={state.veiculoSelecionado}
        />
        <EnviarNotaFiscalModal
          visible={state.visibleEnviarNotaFiscalModal}
          onHide={() => this.setState({ visibleEnviarNotaFiscalModal: false })}
          veiculo={state.veiculoSelecionado}
          evento={state.veiculoSelecionado.entradaEstoque?.chaveNotaFiscalEntrada ? 'VENDA' : 'COMPRA'}
        />
        <CancelarAutorizacaoTransfrenciaModal
          visible={state.visibleCancelarAutorizacaoTransferenciaModal}
          onHide={() => this.setState({ visibleCancelarAutorizacaoTransferenciaModal: false })}
          handleRowExpansion={this.handleRowExpansion}
          placaVeiculo={state.veiculoSelecionado?.placa}
          cnpjEstabelecimentoDestino={state.autorizacaoTransferenciaVeiculo?.cnpjEstabelecimentoDestino}
        />
        <Confirm
          visible={state.visibleConfirm}
          onCancel={() => this.setState({ visibleConfirm: false })}
          title={state.confirmTitle}
          onConfirm={state.onConfirm}
          description={state.confirmDescription}
        />
        <VeiculoModal
          visible={state.visibleVeiculoModal}
          hide={() => this.setState({ visibleVeiculoModal: false })}
          isVeiculoNovo={false}
          primaryKey={state.codigoVeiculo}
          onRegister={async () => await this.onFilter()}
          edicaoHabilitada={true}
        />

        <Modal
          header="Criar veículo usado mock"
          width={45}
          onHide={() => this.setState({ visibleCriarVeiculoMockModal: false })}
          visible={state.visibleCriarVeiculoMockModal}
          footer={
            <Button
              label="Confirmar"
              onClick={async () => {
                await EstoqueUsadosService.criarVeiculoMock(state.sufixoPlacaMock, state.cpfProprietarioMock, state.nomeProprietarioMock, state.veiculoRouboFurtoMock, state.veiculoBaixadoMock, state.ufJurisdicaoMock)
                this.setState({
                  visibleCriarVeiculoMockModal: false,
                  sufixoPlacaMock: '',
                  cpfProprietarioMock: '',
                  nomeProprietarioMock: '',
                  veiculoRouboFurtoMock: false,
                  veiculoBaixadoMock: false,
                  ufJurisdicaoMock: ''
                })
                await this.getAll()
              }}
            />
          }
        >
          <div className="formgrid grid">
            <div className='field col-12 md:col-12'>
              <TextInput
                label="Sufixo placa (últimos 4 digitos da placa). Exemplos: '1E23’, '4567’"
                name="sufixoPlacaMock"
                className={'inputfield w-full mt-2'}
                value={state.sufixoPlacaMock}
                onChange={(e) => this.setState({ sufixoPlacaMock: e.target.value })}
              />
            </div>
            <div className='field col-12 md:col-6'>
              <TextInput
                placeholder="___.___.___-__"
                label="Cpf proprietário"
                className={'inputfield w-full mt-2'}
                mask={'999.999.999-99' }
                value={state.cpfProprietarioMock}
                onChange={(e) => this.setState({ cpfProprietarioMock: e.target.value })}
              />
            </div>
            <div className='field col-12 md:col-6'>
              <TextInput
                label="Nome do proprietário"
                className={'inputfield w-full mt-2'}
                value={state.nomeProprietarioMock}
                onChange={(e) => this.setState({ nomeProprietarioMock: e.target.value })}
              />
            </div>
            <div className='field col-12 md:col-2'>
              <TextInput
                label="UF do veículo"
                className={'inputfield w-full mt-2'}
                value={state.ufJurisdicaoMock}
                onChange={(e) => this.setState({ ufJurisdicaoMock: e.target.value })}
              />
            </div>
            <div className='field col-12 md:col-10'/>

            <div className="field checkbox-container col-4">
              <CheckBoxInput
                label="Veículo com roubo ou furto?"
                value={state.veiculoRouboFurtoMock}
                checked={state.veiculoRouboFurtoMock}
                onChange={() => this.setState({ veiculoRouboFurtoMock: !state.veiculoRouboFurtoMock })}
              />
            </div>
            <div className="field checkbox-container col-4">
              <CheckBoxInput
                label="Veículo já baixado?"
                value={state.veiculoBaixadoMock}
                checked={state.veiculoBaixadoMock}
                onChange={() => this.setState({ veiculoBaixadoMock: !state.veiculoBaixadoMock })}
              />
            </div>
          </div>
        </Modal>
      </Page>
    )
  }
}

export default EstoqueUsados
