import { Divider } from 'primereact/divider'
import React, { useState } from 'react'
import SearchInput from '../../../../components/inputs/SearchInput'
import TextInput from '../../../../components/inputs/TextInput'
import { showErrorMessage, showWarnMessage } from '../../../../components/utils/Message'
import { getPessoaDTO } from '../../../../dtos/cadastro/PessoaDTO'
import { formatCpfOrCnpj, formatCurrencyToDecimal, formatToCurrency } from '../../../../helpers/formaters'
import PessoaService from '../../../../services/cadastro/PessoaService'
import { Button } from 'primereact/button'
import DateInput from '../../../../components/inputs/DateInput'
import PessoaModal from '../../../../forms/cadastro/pessoa/PessoaModal'
import SolicitacaoSaidaService from '../../../../services/veiculosUsados/SolicitacaoSaidaService'
import EnviarNotaFiscalModal from './EnviarNotaFiscalModal'

function SolicitacaoTransferenciaCliente ({ veiculo }) {
  const [cliente, setCliente] = useState(null)
  const [sugestaoClientes, setSugestaoClientes] = useState([])

  const [dataVenda, setDataVenda] = useState(new Date())
  const [valorVenda, setValorVenda] = useState(0)

  const [visibleEnviarNotaFiscalModal, setVisibleEnviarNotaFiscalModal] = useState(false)

  const sugerirClientes = async () => {
    try {
      const pessoaService = new PessoaService()

      const clientes = (await pessoaService.filter(`descricaoFiltro=${cliente.nomraz_pes}`)).data

      if (clientes.length === 0) {
        showWarnMessage('Não foi encontrada nenhuma pessoa!')
      }

      setSugestaoClientes(clientes)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir uma pessoa!')
    }
  }

  function handleChangeCliente (event) {
    setCliente({ nomraz_pes: event.target.value })
  }

  function handleOnRegisterCliente (clienteRegistrado) {
    setCliente(clienteRegistrado)
  }

  async function enviarSolicitacao () {
    try {
      if (!cliente?.codigo_pes) {
        showWarnMessage('Por favor informe um cliente válido!')
        return
      }

      if (!dataVenda) {
        showWarnMessage('Por favor informe uma data de venda válida!')
        return
      }

      if (valorVenda < 0.01) {
        showWarnMessage('Por favor informe um valor de venda válido!')
        return
      }

      await SolicitacaoSaidaService.solicitacaoSaidaEstoque(dataVenda, valorVenda, veiculo, cliente)

      setVisibleEnviarNotaFiscalModal(true)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar enviar a solicitação')
    }
  }

  return veiculo && (
    <div className="formgrid grid">
      <div className="field col-12">
        <h3 className="solicitacao-entrada-header mt-0">INFORMAÇÕES DO COMPRADOR</h3>
        <Divider/>
        <div className="formgrid grid">
          <div className="field col-8 mb-2">
            <SearchInput
              //AutoComplete
              label="Cliente"
              placeholder="Comprador do veículo"
              field="nomraz_pes"
              value={cliente?.nomraz_pes && cliente}
              suggestions={sugestaoClientes}
              completeMethod={sugerirClientes}
              onChange={handleChangeCliente}
              onSelect={(e) => setCliente(e.value)}
              className="inputfield w-full"
              //RegisterModal
              edicaoHabilitada={true}
              ComponenteCadastro={PessoaModal}
              onRegister={handleOnRegisterCliente}
              primaryKeyName="codigo_pes"
              selected={cliente}
              //FiltroModal
              filtroTitle="Pesquisa de pessoas"
              service={PessoaService}
              model={getPessoaDTO}
              columns={[
                { campo: 'nomraz_pes', nome: 'Nome' },
                { campo: 'cgccpf_pes', nome: 'CPF/CNPJ', format: formatCpfOrCnpj }
              ]}
            />
          </div>
          <div className="field col-4 mb-2">
            <TextInput
              label="CPF/CNPJ"
              placeholder="CPF/CNPJ"
              value={formatCpfOrCnpj(cliente?.cgccpf_pes)}
              className="inputfield w-full"
              disabled={true}
            />
          </div>
        </div>
        <Divider/>
        <h3 className="solicitacao-entrada-header">INFORMAÇÕES DO VEÍCULO</h3>
        <Divider/>
        <div className="formgrid grid">
          <div className="field col-8">
            <div>
              <h1>Veículo</h1>
              <div className="mt-2">
                <label>Marca/Modelo: </label><span>{veiculo.marcaModelo}</span>
              </div>
              <div className="mt-1">
                <label>Ano: </label><span>{veiculo.anoFab}/{veiculo.anoMod}</span>
              </div>
              <div className="mt-1">
                <label>Placa/Chassi: </label><span>{veiculo.placa || veiculo.chassi}</span>
              </div>
              <div className="mt-1">
                <label>Renavam: </label><span>{veiculo.renavam}</span>
              </div>
            </div>
          </div>
          <div className="field col-2">
            <TextInput
              label="Valor de venda"
              placeholder="Valor de venda do veículo"
              value={formatToCurrency(valorVenda)}
              onChange={(e) => setValorVenda(formatCurrencyToDecimal(e.target.value))}
              className="inputfield w-full"
            />
          </div>
          <div className="field col-2">
            <DateInput
              label="Data da venda"
              placeholder="Data da venda"
              value={dataVenda}
              onChange={(e) => setDataVenda(e.value)}
              className="inputfield w-full"
            />
          </div>
        </div>
        <div className="flex justify-content-center">
          <Button
            label="Enviar solicitação"
            className="mt-3"
            onClick={async () => enviarSolicitacao()}
          />
        </div>
      </div>
      <EnviarNotaFiscalModal
        visible={visibleEnviarNotaFiscalModal}
        onHide={() => setVisibleEnviarNotaFiscalModal(false)}
        veiculo={veiculo}
        evento="VENDA"
      />
    </div>
  )
}

export default SolicitacaoTransferenciaCliente
