import { formatDateTimeSQL } from '../../helpers/formaters'
import { getStore } from '../../redux/store'

export const getVeiculoDTO = (data = {}) => {
  return {
    codigoVei: data.codigo_vei || null,
    placaVei: data.placa_vei || null,
    renavaVei: data.renava_vei || null,
    chassiVei: data.chassi_vei || null,
    kmatuVei: data.kmatu_vei || null,
    codempVei: data.codemp_vei || null,
    dahmedVei: data.dahmed_vei ? new Date(data.dahmed_vei) : null,
    numcrvVei: data.numcrv_vei || null,
    descriVei: data.descri_vei || null,
    tipcrvVei: data.tipcrv_vei || null,
    anofabVei: data.anofab_vei || null,
    anomodVei: data.anomod_vei || null,
    codsegcrvVei: data.codsegcrv_vei || null,
    tipoCrv: data.tipoCrv || {},
    corVei: data.cor_vei || null,
    idestVei: data.idest_vei || null,
    datentVei: data.datent_vei ? new Date(data.datent_vei) : null,
    datsaiVei: data.datsai_vei ? new Date(data.datsai_vei) : null,
    codpesVei: data.codpes_vei || null,
    codsitVei: data.codsit_vei || null,
    isnovoVei: data.isnovo_vei || null,
    acabadoVei: data.acabado_vei || null,
    descriSit: data.situacao?.descri_sit || null,
    nomrazPes: data.proprietario?.nomraz_pes || null
  }
}

export const postVeiculoDTO = (data = {}) => {
  return {
    codigo_vei: data.codigoVei || null,
    placa_vei: data.placaVei ? data.placaVei.toUpperCase() : null,
    renava_vei: data.renavaVei || null,
    chassi_vei: data.chassiVei || null,
    kmatu_vei: data.kmatuVei || 0,
    codemp_vei: data.codempVei || null,
    dahmed_vei: data.dahmedVei ? formatDateTimeSQL(data.dahmedVei) : null,
    numcrv_vei: data.numcrvVei || null,
    descri_vei: data.descriVei || '',
    tipcrv_vei: data.tipoCrv?.codigo_tcr || null,
    anofab_vei: data.anofabVei || null,
    anomod_vei: data.anomodVei || null,
    codsegcrv_vei: data.codsegcrvVei || null,
    cor_vei: data.corVei || null,
    idest_vei: data.idestVei || null,
    datent_vei: data.datentVei ? formatDateTimeSQL(data.datentVei) : null,
    datsai_vei: data.datsaiVei ? formatDateTimeSQL(data.datsaiVei) : null,
    codpes_vei: data.codpesVei || null,
    codsit_vei: data.codsitVei || 1,
    isnovo_vei: data.isnovoVei ? 1 : 0
  }
}

export const excelToVeiculoDTO = (data = {}) => {
  return {
    placaVei: data.placa.toUpperCase(),
    renavaVei: data.renavam.toString(),
    kmatuVei: data.kmAtual || 0,
    numcrvVei: data.numeroCrv.toString(),
    descriVei: data.descricao,
    tipoCrv: { codigo_tcr: data.tipoCrv[0].toUpperCase() },
    anofabVei: data.anoFabricacao,
    anomodVei: data.anoModelo,
    codsegcrvVei: data.codigoSegurancaCrv.toString(),
    corVei: data.cor,
    codempVei: getStore().empresaLogada.codigo_emp,
    dahmedVei: formatDateTimeSQL(new Date()),
    chassiVei: null,
    codigoVei: null,
    idestVei: null,
    datentVei: null,
    datsaiVei: null,
    codpesVei: null,
    codsitVei: 1,
    isnovoVei: 0
  }
}
