import * as Yup from 'yup'

export const RegistroValidator = Yup.object().shape({
  empresaNomefantasia: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  usuarioNome: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  usuarioTelefone: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  usuarioEmail: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  cnpjEmp: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  nomresfinEmp: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  emaresfinEmp: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  telresfinEmp: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  custoTransacao: Yup.number()
    .nullable()
    .test('testeCustoTransacao', 'Custo por transação precisa ser maior que 0.1 reais!', function (custoTransacao) {
      if (!this.parent.isConviteLojista) {
        if (!custoTransacao || custoTransacao < 0.1) {
          return false
        }

        return true
      } else {
        return true
      }
    })
})
