import { Divider } from 'primereact/divider'
import React, { useState } from 'react'
import SearchInput from '../../../../components/inputs/SearchInput'
import TextInput from '../../../../components/inputs/TextInput'
import { showErrorMessage, showWarnMessage } from '../../../../components/utils/Message'
import { formatCnpj } from '../../../../helpers/formaters'
import { Button } from 'primereact/button'
import EmpresaService from '../../../../services/cadastro/EmpresaService'
import { getEmpresaDTO } from '../../../../dtos/cadastro/EmpresaDTO'
import EmpresaModal from '../../../../forms/cadastro/empresa/EmpresaModal'
import DateInput from '../../../../components/inputs/DateInput'
import SolicitacaoTransferenciaFilialService from '../../../../services/veiculosUsados/SolicitacaoTransferenciaFilialService'

function SolicitacaoTransferenciaFilial ({ veiculo }) {
  const [filial, setFilial] = useState(null)
  const [sugestaoFiliais, setSugestaoFiliais] = useState([])

  const [dataSaida, setDataSaida] = useState(new Date())
  const [kmAtual, setKmAtual] = useState(0)

  const sugerirFiliais = async () => {
    try {
      const empresaService = new EmpresaService()

      const filials = (await empresaService.filter(`apenasFiliais=true&descricaoFiltro=${filial.nomfan_emp}`)).data

      if (filials.length === 0) {
        showWarnMessage('Não foi encontrada nenhuma filial!')
      }

      setSugestaoFiliais(filials)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir uma filial!')
    }
  }

  function handleChangeFilial (event) {
    setFilial({ nomfan_emp: event.target.value })
  }

  function handleOnRegisterFilial (filialRegistrada) {
    setFilial(filialRegistrada)
  }

  async function enviarSolicitacao () {
    try {
      if (!filial?.codigo_emp) {
        showWarnMessage('Por favor informe um filial válido!')
        return
      }

      if (!dataSaida) {
        showWarnMessage('Por favor informe uma data de saída válida!')
        return
      }

      await SolicitacaoTransferenciaFilialService.solicitacaoTransferenciaFilial(kmAtual, dataSaida, veiculo, filial)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar enviar a solicitação')
    }
  }

  return veiculo && (
    <div className="formgrid grid">
      <div className="field col-12">
        <h3 className="solicitacao-entrada-header mt-0">INFORMAÇÕES DA FILIAL</h3>
        <Divider/>
        <div className="formgrid grid">
          <div className="field col-8 mb-2">
            <SearchInput
              //AutoComplete
              label="Filial"
              placeholder="Filial destino da transferência"
              field="nomfan_emp"
              value={filial?.nomfan_emp && filial}
              suggestions={sugestaoFiliais}
              completeMethod={sugerirFiliais}
              onChange={handleChangeFilial}
              onSelect={(e) => setFilial(e.value)}
              className="inputfield w-full"
              //RegisterModal
              onlyVisualizacao={true}
              ComponenteCadastro={EmpresaModal}
              onRegister={handleOnRegisterFilial}
              primaryKeyName="codigo_emp"
              selected={filial}
              //FiltroModal
              filtroTitle="Pesquisa de filiais"
              filtersDefault={{ apenasFiliais: true }}
              service={EmpresaService}
              model={getEmpresaDTO}
              columns={[
                { campo: 'nomfan_emp', nome: 'Nome' },
                { campo: 'cnpj_emp', nome: 'CNPJ', format: formatCnpj }
              ]}
            />
          </div>
          <div className="field col-4 mb-2">
            <TextInput
              label="CNPJ"
              placeholder="CNPJ"
              value={formatCnpj(filial?.cnpj_emp)}
              className="inputfield w-full"
              disabled={true}
            />
          </div>
        </div>
        <Divider/>
        <h3 className="solicitacao-entrada-header">INFORMAÇÕES DO VEÍCULO</h3>
        <Divider/>
        <div className="formgrid grid">
          <div className="field col-8">
            <div>
              <h1>Veículo</h1>
              <div className="mt-2">
                <label>Marca/Modelo: </label><span>{veiculo.marcaModelo}</span>
              </div>
              <div className="mt-1">
                <label>Ano: </label><span>{veiculo.anoFab}/{veiculo.anoMod}</span>
              </div>
              <div className="mt-1">
                <label>Placa/Chassi: </label><span>{veiculo.placa || veiculo.chassi}</span>
              </div>
              <div className="mt-1">
                <label>Renavam: </label><span>{veiculo.renavam}</span>
              </div>
            </div>
          </div>
          <div className="field col-2">
            <TextInput
              label="KM do veículo"
              placeholder="Quilometragem atual"
              value={kmAtual}
              onChange={(e) => setKmAtual(e.target.value)}
              className="inputfield w-full"
            />
          </div>
          <div className="field col-2">
            <DateInput
              label="Data saída estoque"
              placeholder="Data saída estoque"
              value={dataSaida}
              onChange={(e) => setDataSaida(e.value)}
              className="inputfield w-full"
            />
          </div>
        </div>
        <div className="flex justify-content-center">
          <Button
            label="Enviar solicitação"
            className="mt-3"
            onClick={async () => enviarSolicitacao()}
          />
        </div>
      </div>
    </div>
  )
}

export default SolicitacaoTransferenciaFilial
