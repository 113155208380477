// import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { classNames } from 'primereact/utils'
import React, { useEffect, useState } from 'react'
import SelectInput from '../../../components/inputs/SelectInput'
import TextInput from '../../../components/inputs/TextInput'
import FieldErrorMessage from '../../../components/utils/FieldErrorMessage'
import RequiredLabel from '../../../components/utils/RequiredLabel'
import CommonHelper from '../../../helpers/CommonHelper'
import { useValidateInput } from '../../../helpers/useValidateInput'
import EstadosService from '../../../services/localidade/EstadosService'
import CEPService from '../../../services/localidade/CEPService'
import MunicipioService from '../../../services/localidade/MunicipioService'
import DateInput from '../../../components/inputs/DateInput'
import ReactInputMask from 'react-input-mask'
import { Fieldset } from 'primereact/fieldset'

const DadosPrincipaisEmp = ({ form, edicaoHabilitada }) => {
  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)
  const [estados, setEstados] = useState([])
  const [cidades, setCidades] = useState([])
  const [mask, setMask] = useState(null)

  const setFieldTouched = (e) => form.setFieldTouched(e.target.name)
  const isEmpDesp = CommonHelper.isEmpresaDespachante()

  useEffect(async () => {
    await getEstados()

    if (form.values.codufEmp) {
      const estado = await EstadosService.getPorId(form.values.codufEmp)
      await getCidadesPorEstado(estado.sigla_uf)
      form.setFieldValue('estado', estado)
    }
    if (form.values.codmunEmp) {
      const municipio = await MunicipioService.getPorId(form.values.codmunEmp)
      form.setFieldValue('municipio', municipio)
    }
    if (!isEmpDesp) {
      setMask('99.999.999/9999-99')
    } else {
      let cpfCnj = form.values.cnpjEmp
      if (cpfCnj.length === 14) {
        setMask('99.999.999/9999-99')
      } else {
        setMask('999.999.999-99')
      }
    }
  }, [])

  const getEstados = async () => {
    try {
      const estados = await EstadosService.getTodos()
      setEstados(estados)
    } catch (error) {}
  }

  const getCidadesPorEstado = async (estadoSigla) => {
    try {
      const cidades = await MunicipioService.getPorEstado(estadoSigla)
      setCidades(cidades)
    } catch (e) {}
  }

  const handleCepComplete = async (e) => {
    const cep = e.value
    if (CommonHelper.clearDigitsSpecialChars(cep).length === 8) {
      const endereco = await CEPService.getCEP(cep)
      await getCidadesPorEstado(endereco.uf)
      const estado = await EstadosService.getPorSigla(endereco.uf)
      const municipio = await MunicipioService.getPorId(Number(endereco.ibge))

      form.setFieldValue('estado', { sigla_uf: endereco.uf })
      form.setFieldValue('municipio', municipio)
      form.setFieldValue('codufEmp', estado.codigo_uf)
      form.setFieldValue('codmunEmp', municipio.codigo_mun)
      form.setFieldValue('bairroEmp', endereco.bairro)
      form.setFieldValue('endEmp', endereco.logradouro)
      form.validateField('codufEmp')
      form.validateField('codmunEmp')
      form.validateField('bairroEmp')
      form.validateField('endEmp')
    }
  }

  const handleChangeEstado = (e) => {
    const estado = e.target.value
    form.validateField('codufEmp')

    if (estado) {
      form.setFieldValue('codmunEmp', null)
      form.setFieldValue('codufEmp', estado.codigo_uf)
      form.setFieldValue('estado', estado)
      form.setFieldValue('municipio', {})

      getCidadesPorEstado(estado.sigla_uf)
    }
  }

  const handleChangeCidade = (e) => {
    const cidade = e.target.value

    if (cidade) {
      form.setFieldValue('codmunEmp', cidade.codigo_mun)
      form.setFieldValue('municipio', cidade)
    }
  }

  const handleChangeMask = (ev) => {
    setCPFCNPJ(ev)
    let { value } = ev.target
    let nums = value?.replace(/[^0-9]/g, '')
    if ((nums.length < 3 && mask != null) || (nums.length === 11 && isEmpDesp)) {
      setMask(null)
      ev.target.value = nums
    }
    form.handleChange(ev)
  }

  const setCPFCNPJ = (ev) => {
    let { value } = ev.target
    let nums = value?.replace(/[^0-9]/g, '')
    if (nums.length > 11 || !isEmpDesp) {
      setMask('99.999.999/9999-99')
    } else if (nums.length >= 3) {
      setMask('999.999.999-99')
    } else {
      setMask('999.999.999-99')
    }
    ev.target.value = nums
    setFieldTouched(ev)
  }

  return (
    <div className="formgrid grid">
      <div className="field col-3">
        {/* <RequiredLabel label="CNPJ" valid={isFormFieldValid('cnpjEmp')}/>
        <TextInput
          name="cnpjEmp"
          placeholder="CNPJ"
          value={form.values.cnpjEmp}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          className={classNames({ 'p-invalid': isFormFieldValid('cnpjEmp') }, 'inputfield w-full search-cnpj-input')}
          mask="99.999.999/9999-99"
          onBlur={setFieldTouched}
        />
        <FieldErrorMessage message={getFormErrorMessage('cnpjEmp')}/> */}
        <div className="field col-10 form-field no-margin">
          <RequiredLabel label={!isEmpDesp ? 'CNPJ' : 'CPF/CNPJ'} valid={isFormFieldValid('cnpjEmp')}/>
          <ReactInputMask
            mask={mask}
            maskChar="_"
            name="cnpjEmp"
            placeholder={!isEmpDesp ? 'CNPJ' : 'CPF/CNPJ'}
            value={form.values.cnpjEmp}
            onChange={handleChangeMask}
            disabled={!edicaoHabilitada}
            className={classNames({ 'p-invalid': isFormFieldValid('cnpjEmp') }, 'p-inputtext p-component p-filled inputfield w-full')}
            onBlur={setCPFCNPJ}
          />
          <FieldErrorMessage message={getFormErrorMessage('cnpjEmp')}/>
        </div>
      </div>
      <div className="field col-12 md:col-4">
        <RequiredLabel label="Nome/Razão Social" valid={isFormFieldValid('nomrazEmp')}/>
        <TextInput
          value={form.values.nomrazEmp}
          name="nomrazEmp"
          onChange={form.handleChange}
          className={classNames({ 'p-invalid': isFormFieldValid('nomrazEmp') }, 'inputfield w-full')}
          onBlur={setFieldTouched}
          disabled={!edicaoHabilitada}
          placeholder="Nome/Razão Social"
        />
        <FieldErrorMessage message={getFormErrorMessage('nomrazEmp')}/>
      </div>
      <div className="field col-12 md:col-5">
        <RequiredLabel label="Nome fantasia" valid={isFormFieldValid('nomfanEmp')}/>
        <TextInput
          value={form.values.nomfanEmp}
          name="nomfanEmp"
          onChange={form.handleChange}
          className={classNames({ 'p-invalid': isFormFieldValid('nomfanEmp') }, 'inputfield w-full')}
          onBlur={setFieldTouched}
          disabled={!edicaoHabilitada}
          placeholder="Nome fantasia"
        />
        <FieldErrorMessage message={getFormErrorMessage('nomfanEmp')}/>
      </div>
      <div className="field col-12 md:col-9">
        <RequiredLabel label="Email" valid={isFormFieldValid('emailEmp')}/>
        <TextInput
          value={form.values.emailEmp}
          name="emailEmp"
          onChange={form.handleChange}
          className={classNames({ 'p-invalid': isFormFieldValid('emailEmp') }, 'inputfield w-full')}
          onBlur={setFieldTouched}
          disabled={!edicaoHabilitada}
          placeholder="Email"
        />
        <FieldErrorMessage message={getFormErrorMessage('emailEmp')}/>
      </div>
      <div className="field col-12 md:col-3">
        <TextInput
          label="Telefone"
          placeholder="Telefone"
          name="telcomEmp"
          value={form.values.telcomEmp}
          onChange={form.handleChange}
          mask="(99) 99999-999?9"
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-3">
        <RequiredLabel label="CEP" valid={isFormFieldValid('cepEmp')}/>
        <TextInput
          placeholder="CEP"
          name="cepEmp"
          value={form.values.cepEmp}
          onChange={form.handleChange}
          onComplete={handleCepComplete}
          mask="99999-999"
          onBlur={setFieldTouched}
          disabled={!edicaoHabilitada}
          className={classNames({ 'p-invalid': isFormFieldValid('cepEmp') }, 'inputfield w-full')}
        />
        <FieldErrorMessage message={getFormErrorMessage('cepEmp')}/>
      </div>
      <div className="field col-12 md:col-5">
        <RequiredLabel label="Endereço" valid={isFormFieldValid('endEmp')}/>
        <TextInput
          value={form.values.endEmp}
          name="endEmp"
          onChange={form.handleChange}
          className={classNames({ 'p-invalid': isFormFieldValid('endEmp') }, 'inputfield w-full')}
          onBlur={setFieldTouched}
          disabled={!edicaoHabilitada}
          placeholder="Endereço"
        />
        <FieldErrorMessage message={getFormErrorMessage('endEmp')}/>
      </div>
      <div className="field col-6 md:col-1">
        <TextInput
          label="Número"
          placeholder="Número"
          name="numendEmp"
          maxLength={10}
          className="inputfield w-full"
          value={form.values.numendEmp}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
        />
      </div>
      <div className="field col-12 md:col-3">
        <TextInput
          value={form.values.comendEmp}
          label="Complemento"
          name="comendEmp"
          onChange={form.handleChange}
          className="inputfield w-full"
          disabled={!edicaoHabilitada}
          placeholder="Complemento"
        />
      </div>
      <div className="field col-12 md:col-3">
        <RequiredLabel label="Bairro" valid={isFormFieldValid('bairroEmp')}/>
        <TextInput
          value={form.values.bairroEmp}
          name="bairroEmp"
          onChange={form.handleChange}
          className={classNames({ 'p-invalid': isFormFieldValid('bairroEmp') }, 'inputfield w-full')}
          onBlur={setFieldTouched}
          disabled={!edicaoHabilitada}
          placeholder="Bairro"
        />
        <FieldErrorMessage message={getFormErrorMessage('bairroEmp')}/>
      </div>
      <div className="field col-12 md:col-5">
        <RequiredLabel label="Estado" valid={isFormFieldValid('codufEmp')}/>
        <SelectInput
          value={form.values.estado}
          options={estados}
          onChange={handleChangeEstado}
          placeholder="Selecione"
          name="estado"
          filter={CommonHelper.isDesktop()}
          optionLabel="descri_uf"
          dataKey="sigla_uf"
          noFloatLabel
          disabled={!edicaoHabilitada}
          className={classNames({ 'p-invalid': isFormFieldValid('codufEmp') }, 'inputfield w-full')}
          onBlur={() => form.setFieldTouched('codufEmp')}
        />
        <FieldErrorMessage message={getFormErrorMessage('codufEmp')}/>
      </div>
      <div className="field col-12 md:col-4">
        <RequiredLabel label="Município" valid={isFormFieldValid('codmunEmp')}/>
        <SelectInput
          value={form.values.municipio}
          options={cidades}
          onChange={handleChangeCidade}
          placeholder="Selecione"
          name="municipio"
          filter={CommonHelper.isDesktop()}
          optionLabel="descri_mun"
          dataKey="codigo_mun"
          noFloatLabel
          disabled={!edicaoHabilitada}
          className={classNames({ 'p-invalid': isFormFieldValid('codmunEmp') }, 'inputfield w-full')}
          onBlur={() => form.setFieldTouched('codmunEmp')}
        />
        <FieldErrorMessage message={getFormErrorMessage('codmunEmp')}/>
      </div>
      <div className="field checkbox-container col-3">
        <Checkbox
          name="ativoEmp"
          value={form.values.ativoEmp}
          onChange={form.handleChange}
          checked={form.values.ativoEmp}
          disabled={CommonHelper.isUsuarioAdmin() ? !edicaoHabilitada : true }
        />
        <label className="p-checkbox-label mb-0 pl-3">Ativo</label>
      </div>
      {CommonHelper.isUsuarioAdmin() && (
        <div className="field col-12 md:col-3">
          <DateInput
            label="Data de desativação"
            placeholder="Data de desativação"
            name="datdesEmp"
            value={form.values.datdesEmp}
            disabled="disabled"
            className="inputfield w-full"
          />
        </div>
      )}
      <div className="field checkbox-container col-3"></div>
      <div className="field col-12 md:col-8">
        <Fieldset legend="Responsável pelo financeiro">
          <div className="field col-6 md:col-12">
            <RequiredLabel label="Nome" valid={isFormFieldValid('nomresfinEmp')}/>
            <TextInput
              value={form.values.nomresfinEmp}
              name="nomresfinEmp"
              onChange={form.handleChange}
              className={classNames({ 'p-invalid': isFormFieldValid('nomresfinEmp') }, 'inputfield w-full')}
              onBlur={setFieldTouched}
              disabled={!edicaoHabilitada}
              placeholder="Nome"
            />
            <FieldErrorMessage message={getFormErrorMessage('nomresfinEmp')}/>
          </div>
          <div className="field col-6 md:col-12">
            <RequiredLabel label="Email" valid={isFormFieldValid('emaresfinEmp')}/>
            <TextInput
              value={form.values.emaresfinEmp}
              name="emaresfinEmp"
              onChange={form.handleChange}
              className={classNames({ 'p-invalid': isFormFieldValid('emaresfinEmp') }, 'inputfield w-full')}
              onBlur={setFieldTouched}
              disabled={!edicaoHabilitada}
              placeholder="Email"
            />
            <FieldErrorMessage message={getFormErrorMessage('emaresfinEmp')}/>
          </div>
          <div className="field col-6 md:col-12">
            <RequiredLabel label="Telefone" valid={isFormFieldValid('telresfinEmp')}/>
            <TextInput
              value={form.values.telresfinEmp}
              name="telresfinEmp"
              onChange={form.handleChange}
              className={classNames({ 'p-invalid': isFormFieldValid('telresfinEmp') }, 'inputfield w-full')}
              onBlur={setFieldTouched}
              mask="(99) 99999-999?9"
              disabled={!edicaoHabilitada}
              placeholder="Telefone"
            />
            <FieldErrorMessage message={getFormErrorMessage('telresfinEmp')}/>
          </div>
        </Fieldset>
      </div>
    </div>
  )
}

export default DadosPrincipaisEmp
