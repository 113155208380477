import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SplitButton } from 'primereact/splitbutton'
import { logout, setCredentials } from '../../helpers/auth'
import { Button } from 'primereact/button'
import TextInput from '../inputs/TextInput'
import InputFile from '../inputs/InputFile'
import { showErrorMessage, showSuccessMessage } from '../utils/Message'
import AES from '../../helpers/AES'
import UsuarioService from '../../services/cadastro/UsuarioService'
import Modal from '../utils/Modal'
import EmpresaService from '../../services/cadastro/EmpresaService'
import ChangePasswordModal from '../utils/ChangePasswordModal'
import { dispatch, getStore } from '../../redux/store'
import { setCertificadoValidoIte, setCertificadoValidoMontadora, setCertificadoValidoVendaVeiculos, setEmpresaLogada } from '../../redux/actions'
import CommonHelper from '../../helpers/CommonHelper'
import { Badge } from 'primereact/badge'

export class AppTopbar extends Component {
  static defaultProps = {
    onToggleMenu: null
  }

  static propTypes = {
    onToggleMenu: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      busca: '',
      redirect: false,
      selectedCity: null,
      selectedFile: null,
      certificatePassword: '',
      visibleChangePassword: false,
      visibleUploadCertificate: false,
      visibleVerificaCertificadoModal: false,
      certificadoInvalido: false,
      required: false
    }

    this.empresaService = new EmpresaService()
    this.usuarioService = new UsuarioService()
  }

  logout = () => {
    logout()
    window.location.href = '/login'
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  UploadCertificate = async () => {
    let { selectedFile, certificatePassword } = this.state
    try {
      if (selectedFile === null) throw Object({ message: 'Adicione o certificado digital!' })
      if (certificatePassword === '') throw Object({ message: 'O campo senha do certificado deve ser preenchido!' })

      const data = new FormData()
      data.append('certificado', selectedFile)
      data.append('senha', AES.encryptWithAES(certificatePassword))

      await this.empresaService.uploadCertificate(data)
      await EmpresaService.getEmpresaLogada().then(empresa => {
        dispatch(setEmpresaLogada(empresa))
      })

      const renaveZeroKm = getStore().empresaLogada.rennov_emp
      const renaveUsados = getStore().empresaLogada.renusa_emp
      const renaveIte = getStore().empresaLogada.renite_emp
      const renaveMontadora = getStore().empresaLogada.renmon_emp

      if (renaveUsados || renaveZeroKm) {
        try {
          await EmpresaService.verificaCertificadoComSerpro('vendaVeiculos')
          dispatch(setCertificadoValidoVendaVeiculos(true))
        } catch (error) {
          dispatch(setCertificadoValidoVendaVeiculos(false))
          if (error.message.includes('por favor importar o certificado da empresa')) {
            throw Error(error.message)
          }
        }
      }
      if (renaveIte) {
        try {
          await EmpresaService.verificaCertificadoComSerpro('ite')
          dispatch(setCertificadoValidoIte(true))
        } catch (error) {
          dispatch(setCertificadoValidoIte(false))
          if (error.message.includes('por favor importar o certificado da empresa')) {
            throw Error(error.message)
          }
        }
      }
      if (renaveMontadora) {
        try {
          await EmpresaService.verificaCertificadoComSerpro('montadora')
          dispatch(setCertificadoValidoMontadora(true))
        } catch (error) {
          dispatch(setCertificadoValidoMontadora(false))
          if (error.message.includes('por favor importar o certificado da empresa')) {
            throw Error(error.message)
          }
        }
      }
      this.onCloseDialog()
      this.setState({ visibleVerificaCertificadoModal: true })
      showSuccessMessage('Certificado importado com sucesso!')
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro. Tente novamente mais tarde.')
    }
    this.setState({ required: true })
  }

  onCloseDialog = async () => {
    this.setState({
      visibleChangePassword: false,
      visibleUploadCertificate: false,
      visibleVerificaCertificadoModal: false,
      selectedFile: null,
      certificateName: 'Selecione um arquivo',
      certificatePassword: '',
      certificadoInvalido: false,
      required: false
    })
  }

  async handleTrocarEmpresa () {
    try {
      const codEmp = getStore().usuarioLogado.codemp_usu

      if (codEmp) {
        const credentials = await EmpresaService.trocarEmpresa(codEmp)
        setCredentials(credentials)
        dispatch(setEmpresaLogada({}))

        this.props.history.push('/home')
        window.location.reload()
      }
    } catch (error) {
      showErrorMessage(error.message || 'Erro ao tentar trocar de empresa!')
    }
  }

  getActions () {
    const empresa = getStore().empresaLogada

    const actions = [{
      label: 'Trocar Senha',
      icon: 'pi pi-fw pi-unlock',
      command: () => {
        this.setState({ visibleChangePassword: true })
      }
    }]

    if (!CommonHelper.isUsuarioDespachante()) {
      actions.push({
        label: 'Certificado Digital',
        icon: 'pi pi-fw pi-key',
        command: () => {
          this.setState({ visibleUploadCertificate: true })
        }
      })
    }

    if (empresa.cerkey_emp) {
      actions.push({
        label: 'Validar Credenciamento',
        icon: 'pi pi-fw pi-key',
        command: () => {
          this.setState({ visibleVerificaCertificadoModal: true })
        }
      })
    }

    actions.push({
      label: (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div>Central de treinamentos</div><div><Badge severity="success" style={{ position: 'relative' }} value="Novo" /></div></div>),
      icon: 'pi pi-fw pi-book',
      command: () => {
        window.open('https://materiais.sances.com.br/renaveauto-tutoriais', '_blank')
      }
    })

    actions.push({
      label: 'Sair',
      icon: 'pi pi-fw pi-power-off',
      command: () => {
        this.logout()
      }
    })

    return actions
  }

  async validarCertificado (tipoValidamento) {
    try {
      await EmpresaService.verificaCertificadoComSerpro(tipoValidamento)
      switch (tipoValidamento) {
      case 'vendaVeiculos':
        dispatch(setCertificadoValidoVendaVeiculos(true))
        break
      case 'ite':
        dispatch(setCertificadoValidoIte(true))
        break
      case 'montadora':
        dispatch(setCertificadoValidoMontadora(true))
        break
      }
      showSuccessMessage('Validação efetuada com sucesso!')
      this.onCloseDialog()
    } catch (error) {
      if (!error.message.includes('por favor importar o certificado da empresa')) {
        this.setState({ certificadoInvalido: true })
      }
      switch (tipoValidamento) {
      case 'vendaVeiculos':
        dispatch(setCertificadoValidoVendaVeiculos(false))
        break
      case 'ite':
        dispatch(setCertificadoValidoIte(false))
        break
      case 'montadora':
        dispatch(setCertificadoValidoMontadora(false))
        break
      }
      showErrorMessage(error.message || 'Falha de comunicação com a SERPRO!')
    }
  }

  render () {
    const { certificateName, certificatePassword, required } = this.state
    const usuario = getStore().usuarioLogado
    const renaveZeroKm = getStore().empresaLogada.rennov_emp
    const renaveUsados = getStore().empresaLogada.renusa_emp
    const renaveIte = getStore().empresaLogada.renite_emp
    const renaveMontadora = getStore().empresaLogada.renmon_emp
    return (
      <>
        <div className="layout-topbar clearfix">
          <button className="p-link layout-menu-button" onClick={this.props.onToggleMenu}>
            <span className="pi pi-bars" />
          </button>
          <div className="layout-topbar-menu" >
            <div className="flex align-items-center" >
              {(CommonHelper.isUsuarioDespachante() && !CommonHelper.isEmpresaDespachante()) ? (
                <Button
                  label="Área do despachante"
                  className="mr-6 p-button-secondary"
                  onClick={() => this.handleTrocarEmpresa()}
                />
              ) : <></>}
            </div>
            <span className="small-user-menu" >
              <SplitButton label={' '} model={this.getActions()} className="p-mr-2 user-menu" />
            </span>
            <span className="big-user-menu" >
              <SplitButton label={usuario.nome_usu} model={this.getActions()} className="p-mr-2 user-menu" />
            </span>
          </div>
        </div>
        <ChangePasswordModal
          visible={this.state.visibleChangePassword}
          usuarioLogado={usuario}
          hide={this.onCloseDialog}
          reloadUsuario={this.getUsuarioLogado}
        />
        <Modal
          header="Certificado Digital"
          focusOnShow={false}
          visible={this.state.visibleUploadCertificate}
          width={25}
          onHide={this.onCloseDialog}
          footer={
            <Button
              label="Enviar"
              icon="pi pi-check"
              onClick={this.UploadCertificate}
              autoFocus={true}
            />
          }
        >
          <div className="p-grid p-fluid">
            <div className="field col-12">
              <div>
                <label>Extensão pfx ou p12</label>
                <InputFile
                  uploadHandler={(event) => this.setState({ selectedFile: event.files[0], certificateName: event.files[0].name })}
                  label={{ label: certificateName ? certificateName : 'Selecione o arquivo' }}
                />
              </div>
            </div>
            <div className="field col-12">
              <div>
                <TextInput
                  type="password"
                  label="Senha do certificado"
                  name="certificatePassword"
                  required={required}
                  id="certificatePassword"
                  value={certificatePassword}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          header="Validação do credenciamento SERPRO"
          visible={this.state.visibleVerificaCertificadoModal}
          focusOnShow={false}
          onHide={this.onCloseDialog}
          width={35}
        >
          {(renaveUsados || renaveZeroKm) ? (
            <Button
              label="Validar credenciamento - Venda de veículos"
              onClick={async () => await this.validarCertificado('vendaVeiculos')}
              className="inputfield w-full"
            />
          ) : <></>}
          {renaveIte ? (
            <Button
              label="Validar credenciamento - ITE"
              onClick={async () => await this.validarCertificado('ite')}
              className="inputfield w-full mt-2"
            />
          ) : <></>}
          {renaveMontadora ? (
            <Button
              label="Validar credenciamento - Montadora"
              onClick={async () => await this.validarCertificado('montadora')}
              className="inputfield w-full mt-2"
            />
          ) : <></>}
          {this.state.certificadoInvalido && (
            <>
              <div className="flex justify-content-center mt-4">
                <div className="red bold mb-4">Falha de comunicação com a SERPRO</div>
              </div>
              <div className="ml-6">
                <div>
                  <label className="bold">Verifique </label><label>se:</label>
                </div>
                <div className="ml-3">
                  <div>
                    <label className="bold">• </label>
                    <label>O </label>
                    <label className="bold">credenciamento </label>
                    <label>foi </label>
                    <label className="bold">submetido no Credencia.</label>
                  </div>
                  <div>
                    <label className="bold">• </label>
                    <label>A </label>
                    <label className="bold">contratação do E-commerce </label>
                    <label>foi </label>
                    <label className="bold">finalizada.</label>
                  </div>
                  <div>
                    <label className="bold">• </label>
                    <label>A </label>
                    <label className="bold">habilitação do certificado digital </label>
                    <label>foi </label>
                    <label className="bold">finalizada.</label>
                  </div>
                  <div>
                    <label className="bold">• </label>
                    <label>O </label>
                    <label className="bold">Certificado </label>
                    <label>digital </label>
                    <label className="bold">importado no Credencia </label>
                    <label>é o </label>
                    <label className="bold">mesmo importado no RenaveAuto.</label>
                  </div>
                  <div>
                    <label className="bold">• </label>
                    <label>O </label>
                    <label className="bold">Certificado </label>
                    <label>está </label>
                    <label className="bold">vencido.</label>
                  </div>
                </div>
                <div className="mt-3">
                  <span>Para mais informações acesse o </span>
                  <span
                    className="underline cursor-pointer bold"
                    onClick={() => window.open('https://sances.com.br/blog/credenciamento-renave/')}
                  >
                Manual do Credencia.
                  </span>
                </div>
              </div>
            </>
          )}
        </Modal>
      </>
    )
  }
}
