import classNames from 'classnames'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import React from 'react'
import List from '../../classes/List'
import SelectInput from '../../components/inputs/SelectInput'
import Container from '../../components/layout/Container'
import Page from '../../components/layout/Page'
import DataList from '../../components/utils/DataList'
import { getHistoricoMovimentacaoDTO } from '../../dtos/zeroKm/HistoricoMovimentacaoDTO'
import CommonHelper from '../../helpers/CommonHelper'
import { formatDateTime, formatPlaca } from '../../helpers/formaters'
import HistoricoMovimentacaoService from '../../services/zeroKm/historico/HistoricoMovimentacaoService'
import XLSX from 'xlsx'
import UsuarioService from '../../services/cadastro/UsuarioService'
import { TreeSelect } from 'primereact/treeselect'
import { getStore } from '../../redux/store'
import { Checkbox } from 'primereact/checkbox'
import DateInput from '../../components/inputs/DateInput'

class HistoricoMovimentacao extends List {
  constructor (props) {
    super(
      props,
      getHistoricoMovimentacaoDTO,
      HistoricoMovimentacaoService,
      '',
      '',
      't'
    )

    this.state = {
      ...this.state,
      dataMovimentacaoInicial: null,
      dataMovimentacaoFinal: null,
      operacao: null,
      cuscreCheckBox: false,
      companies: [],
      operacaoOptions: [],
      selectedCompanies: {
        0: { checked: false, partialChecked: true },
        '0-0': { checked: true, partialChecked: false }
      }
    }

    //#region Criando colunas.

    this.setColumns([
      {
        key: 'nomfan_emp',
        component: (
          <Column
            style={{ width: '20%' }}
            field="nomfan_emp"
            header="Empresa"
            sortable
            headerClassName="nomfan_emp" />
        )
      },
      {
        key: 'chassi_mov',
        component: (
          <Column
            style={{ width: '15%' }}
            header="Placa/Chassi"
            body={data => data.placa_mov ? formatPlaca(data.placa_mov) : data.chassi_mov}
            sortable
            headerClassName="chassi_mov" />
        )
      },
      {
        key: 'descri_vei',
        component: (
          <Column
            style={{ width: '15%' }}
            field="descri_vei"
            header="Marca/Modelo"
            sortable
            headerClassName="descri_vei" />
        )
      },
      {
        key: 'cor_vei',
        component: (
          <Column
            style={{ width: '10%' }}
            field="cor_vei"
            header="Cor"
            sortable
            headerClassName="cor_vei" />
        )
      },
      {
        key: 'nome_usu',
        component: (
          <Column
            style={{ width: '15%' }}
            field="nome_usu"
            header="Usuario"
            sortable
            headerClassName="nome_usu" />
        )
      },
      {
        key: 'descri_ope',
        component: (
          <Column
            style={{ width: '15%' }}
            field="descri_ope"
            header="Operação"
            sortable
            headerClassName="descri_ope" />
        )
      },
      {
        key: 'dathor_mov',
        component: (
          <Column
            style={{ width: '10%' }}
            field="dathor_mov"
            header="Movimentação"
            body={data => data.dathor_mov ? formatDateTime(data.dathor_mov) : ''}
            sortable
            headerClassName="dathor_mov" />
        )
      }
    ])

    //#endregion
  }

  async componentDidMount () {
    await super.componentDidMount()
    const operacaoOptions = await HistoricoMovimentacaoService.getOperacoes()
    const empresasAcesso = await UsuarioService.empresasAcessoUsuarioLogado()
    const empresasAcessoAtivas = empresasAcesso.filter((empresa) => empresa.ativo_emp)

    //Colocando a empresa logada como primeiro registro do array
    const empresaLogada = empresasAcessoAtivas.filter((empresa) => empresa.codigo_emp === getStore().empresaLogada.codigo_emp).shift()
    empresasAcessoAtivas.splice(empresasAcessoAtivas.indexOf(empresaLogada), 1)
    empresasAcessoAtivas.unshift(empresaLogada)
    const empresas = empresasAcessoAtivas.map((empresa, idx) => {
      return {
        key: `0-${idx}`,
        label: empresa.nomfan_emp,
        data: empresa.codigo_emp
      }
    })

    const companies = [{
      key: 0,
      label: 'Empresas',
      children: [...empresas]
    }]

    this.setState({ operacaoOptions, companies })
  }

  handleExportarExcel = async () => {
    const historicoMovimentacaoService = new HistoricoMovimentacaoService()
    const response = await historicoMovimentacaoService.filter(this.toFilter())

    const historicoMovimentacao = []
    for (const registro of response.data) {
      const row = {
        Empresa: registro.nomfan_emp,
        Chassi: registro.chassi_mov,
        'Descricao do veículo': registro.descri_vei,
        Cor: registro.cor_vei,
        'Nome do usuário': registro.nome_usu,
        Operacao: registro.descri_ope,
        Data: formatDateTime(registro.dathor_mov)
      }
      historicoMovimentacao.push(row)
    }

    const workSheet = XLSX.utils.json_to_sheet(historicoMovimentacao)

    const workBook = { Sheets: { data: workSheet }, SheetNames: ['data'] }

    XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' })
    XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' })
    XLSX.writeFile(workBook, `Historico-Movimentacao-${new Date().getTime()}.xlsx`)
  }

  onFilterClick = () => {
    const { filter, selectedCompanies, companies } = this.state
    filter.dataMovimentacaoInicial = CommonHelper.dateToAmerican(this.state.dataMovimentacaoInicial)
    filter.dataMovimentacaoFinal = CommonHelper.dateToAmerican(this.state.dataMovimentacaoFinal)

    if (this.state.cuscreCheckBox) {
      filter.mostraApenasCuscre = true
      filter.codope = null
    } else {
      filter.codope = this.state.operacao ? this.state.operacao.codigo_ope : null
      filter.mostraApenasCuscre = null
    }

    const idsEmpresas = []

    for (const propertyName of Object.getOwnPropertyNames(selectedCompanies)) {
      if (propertyName.substring(2))
        idsEmpresas.push(companies[0].children[Number(propertyName.substring(2))].data)
    }

    if (idsEmpresas.length === 0)
      idsEmpresas.push('nenhuma')

    filter.idsEmpresas = idsEmpresas
    this.setState({ filter })
    this.onFilter()
  }

  expandedFiltersTemplate = () => (
    <>
      <div className="field col-12 md:col-4">
        <label className="label">Data da movimentação</label>
        <div className="formgrid grid">
          <div className="col-6 md:col-6">
            <DateInput
              name="dataMovimentacaoInicial"
              placeholder="Data Inicial"
              className="inputfield w-full"
              value={this.state.dataMovimentacaoInicial}
              onChange={(e) => this.setState({ dataMovimentacaoInicial: e.target.value })} />
          </div>
          <div className="col-6 md:col-6">
            <DateInput
              name="dataMovimentacaoFinal"
              placeholder="Data Final"
              className="inputfield w-full"
              value={this.state.dataMovimentacaoFinal}
              onChange={(e) => this.setState({ dataMovimentacaoFinal: e.target.value })} />
          </div>
        </div>
      </div>
      <div className="col-12 md:col-5">
        <label className="label">Empresas</label>
        <TreeSelect
          value={this.state.selectedCompanies}
          options={this.state.companies}
          onChange={(e) => this.setState({ selectedCompanies: e.value })}
          display="chip"
          selectionMode="checkbox"
          className="inputfield w-full"
          placeholder="Select Items"
          filter
        />
      </div>
      <div className="col-12 md:col-4">
        <SelectInput
          value={this.state.operacao}
          label="Operação"
          name="operacao"
          placeholder="- Selecione uma operação -"
          className="inputfield w-full"
          filter={CommonHelper.isDesktop()}
          options={this.state.operacaoOptions}
          optionLabel="descri_ope"
          dataKey="codigo_ope"
          onChange={(e) => this.setState({ operacao: e.target.value })}
          noFloatLabel
          showClear
          disabled={this.state.cuscreCheckBox}
        />
      </div>
      <div className="field checkbox-container col-8 margin-top">
        <Checkbox
          value={this.state.cuscreCheckBox}
          onChange={() => this.setState({ cuscreCheckBox: !this.state.cuscreCheckBox })}
          checked={this.state.cuscreCheckBox}
        />
        <label className="p-checkbox-label mb-0 pl-2">Mostrar apenas operações que custam crédito.</label>
      </div>
      <div className="col-12 flex justify-content-end mb-4">
        <Button label="Buscar" icon="pi pi-search" onClick={this.onFilterClick} />
      </div>
    </>
  )

  render () {
    const state = this.state

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Histórico de movimentação</h4>
          <div className="page-header-buttons">
            <Button
              label="Exportar em excel"
              onClick={() => this.handleExportarExcel()}
            />
          </div>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: state.opcaoSelecionada === 't' }, 'filter-option')}>
              <span className="option-label">Todos</span>
              <div className="option-quantity">
                {state.totalRecords}
              </div>
            </div>
          </div>
        </div>
        <Container>
          <DataList
            data={state.list}
            selected={state.selected}
            rows={state.rows}
            onSelect={this.onSelect}
            totalRecords={state.totalRecords}
            first={state.first}
            onPage={this.onPageChange}
            page={state.page}
            showFilter
            filterPlaceholder="Procurar histórico de movimentação"
            filterName="descricaoFiltro"
            responsive
            filterOnChange={this.handleChangeFilter}
            expandedFiltersTemplate={this.expandedFiltersTemplate}
            onFilter={this.onFilter}
          >
            {this.columns.map((column) => column.component)}
          </DataList>
        </Container>
      </Page>
    )
  }
}

export default HistoricoMovimentacao
