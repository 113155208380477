import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import React from 'react'
import { IoWarningOutline } from 'react-icons/io5'
import List from '../../classes/List'
import TextAreaInput from '../../components/inputs/TextAreaInput'
import TextInput from '../../components/inputs/TextInput'
import Container from '../../components/layout/Container'
import Page from '../../components/layout/Page'
import DataList from '../../components/utils/DataList'
import { showErrorMessage, showSuccessMessage } from '../../components/utils/Message'
import Modal from '../../components/utils/Modal'
import { getEstoqueRenaveDTO } from '../../dtos/renave/EstoqueRenaveDTO'
import CommonHelper from '../../helpers/CommonHelper'
import { formatDateTime } from '../../helpers/formaters'
import EstoqueRenaveService from '../../services/suporte/EstoqueRenaveService'
import XmlImportService from '../../services/zeroKm/xml/XmlImportService'
import XmlImportSuporteService from '../../services/zeroKm/xml/XmlImportSuporteService'

class ConsultaChassi extends List {
  constructor (props) {
    super(
      props,
      getEstoqueRenaveDTO,
      XmlImportSuporteService,
      '',
      ''
    )

    this.state = {
      ...this.state,
      visibleErrorModal: false,
      erroMensagem: '',
      erroDetalhe: '',
      erroTitulo: '',
      erroXml: '',
      requestBody: '{}',
      visibleRequestResponseModal: false,
      responseBody: '{}',
      retornoApiRenave: '',
      visibleResponseModal: false,
      visibleConsultarChassiRenave: false,
      chassi: ''
    }

    this.setColumns([
      {
        key: 'codigo',
        component: (
          <Column
            header="Codigo"
            field="codigo"
            style={{ width: '6%', maxWidth: '6%' }}
            sortable
            sortField="null1"
            headerClassName="codigo"
          />
        )
      },
      {
        key: 'dataprocessamentoxml',
        component: (
          <Column
            header="Data/Hora processamento xml"
            field="dataprocessamentoxml"
            style={{ width: '10%', maxWidth: '10%' }}
            body={data => data.dataprocessamentoxml ? formatDateTime(data.dataprocessamentoxml) : ''}
            sortable
            sortField="null2"
            headerClassName="dataprocessamentoxml"
          />
        )
      },
      {
        key: 'dataprocessamentoapi',
        component: (
          <Column
            header="Data/Hora processamento api"
            field="dataprocessamentoapi"
            style={{ width: '10%', maxWidth: '10%' }}
            body={data => data.dataprocessamentoapi ? formatDateTime(data.dataprocessamentoapi) : ''}
            sortable
            sortField="null3"
            headerClassName="dataprocessamentoapi"
          />
        )
      },
      {
        key: 'cnpjemitente',
        component: (
          <Column
            header="CNPJ emitente"
            field="cnpjemitente"
            style={{ width: '10%', maxWidth: '10%' }}
            sortable
            sortField="null4"
            headerClassName="cnpjemitente"
          />
        )
      },
      {
        key: 'cpfcnpj',
        component: (
          <Column
            header="CPF/CNPJ destinatário"
            field="cpfcnpj"
            style={{ width: '10%', maxWidth: '10%' }}
            sortable
            sortField="null6"
            headerClassName="cpfcnpj"
          />
        )
      },
      {
        key: 'tiponf',
        component: (
          <Column
            header="Tipo"
            field="tiponf"
            body={data => {
              if (data.tiponf === 'E')
                return <span className="colored-label blue fixed-length">Entrada</span>

              if (data.tiponf === 'S')
                return <span className="colored-label light-green fixed-length">Saída</span>

              if (data.tiponf === 'T')
                return <span className="colored-label yellow fixed-length">Transferência</span>
            }}
            style={{ width: '10%', maxWidth: '10%' }}
            sortable
            sortField="null8"
            headerClassName="tiponf"
          />
        )
      },
      {
        component: (
          <Column
            header="Status XML"
            field="erroxml"
            body={data =>
              data.erroxml
                ? <IoWarningOutline
                  className="info-selection"
                  onClick={() => this.setState({ erroXml: data.erroxml, visibleErrorModal: true })}
                />
                : <i className="pi pi-check info-selection  cursor-auto"></i>
            }
            style={{ width: '5%', maxWidth: '5%' }}
          />
        )
      },
      {
        component: (
          <Column
            header="Status API"
            field="errotitulo"
            body={data =>
              data.errotitulo || data.erromensagem || data.errodetalhe
                ? <IoWarningOutline
                  className="info-selection"
                  onClick={() => this.setState({ erroMensagem: data.erromensagem, erroDetalhe: data.errodetalhe, erroTitulo: data.errotitulo, visibleErrorModal: true })}
                />
                : <i className="pi pi-check info-selection  cursor-auto"></i>
            }
            style={{ width: '5%', maxWidth: '5%' }}
          />
        )
      },
      {
        component: (
          <Column
            header="Request/Response"
            field="logrequestbody"
            body={data =>
              (data.logrequestbody || data.logresponsebody)
                ? <i
                  className="pi pi-info-circle info-selection"
                  onClick={() => this.setState({ requestBody: data.logrequestbody, responseBody: data.logresponsebody, visibleRequestResponseModal: true })}
                />
                : ''
            }
            style={{ width: '5%', maxWidth: '5%' }}
          />
        )
      },
      {
        component: (
          <Column
            header="Download xml"
            body={data =>
              <i className="pi pi-download info-selection" onClick={() => this.downloadXml(data)}></i>
            }
            style={{ width: '10%', maxWidth: '10%' }}
          />
        )
      }
    ])
  }

  async downloadXml (data) {
    try {
      const arquivoXmlBase64 = await XmlImportService.downloadXml(data.arquivo)
      CommonHelper.downloadFile('data:application/xml;base64,', arquivoXmlBase64, `XML-${data.codigo}.xml`)
      showSuccessMessage('Arquivo XML baixado com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar fazer download do arquivo XML')
    }
  }

  async componentDidMount () {
    const filter = { chassi: ' ' }
    this.setState({ filter }, this.onFilter)
    this.addEventListeners()
  }

  render () {
    const state = this.state

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Consulta chassi</h4>
          <div className="page-header-buttons">
            <Button
              label="Consultar chassi na api do renave"
              onClick={() => this.setState({ visibleConsultarChassiRenave: true })}
              className="mr-2"
            />
          </div>
        </div>
        <Container>
          <DataList
            data={state.list}
            selected={state.selected}
            rows={state.rows}
            onSelect={this.onSelect}
            totalRecords={state.totalRecords}
            first={state.first}
            onPage={this.onPageChange}
            page={state.page}
            showFilter
            filterPlaceholder="Digite um chassi."
            filterName="chassi"
            responsive
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
          >
            {this.columns.map((column) => column.component)}
          </DataList>
        </Container>
        <Modal
          header="Erro no xml import"
          visible={state.visibleErrorModal}
          onHide={() => this.setState({ visibleErrorModal: false })}
          width={45}
        >
          {state.erroXml && (
            <div>
              <b>Erro Xml: </b>{state.erroXml}
            </div>
          )}
          {state.erroTitulo && (
            <div>
              <b>Erro titulo: </b>{state.erroTitulo}
            </div>
          )}
          {state.erroMensagem && (
            <div>
              <b>Erro mensagem: </b>{state.erroMensagem}
            </div>
          )}
          {state.erroDetalhe && (
            <div>
              <b>Erro detalhe: </b>{state.erroDetalhe}
            </div>
          )}
        </Modal>
        <Modal
          header="Request/Response"
          visible={state.visibleRequestResponseModal}
          onHide={() => this.setState({ visibleRequestResponseModal: false })}
          width={70}
        >
          <div className="col-12 w-full">
            <label>Request Body</label>
            <textarea className="w-full" rows={40} value={JSON.stringify(JSON.parse(state.requestBody), null, 4)}/>
          </div>
          <div className="col-12 w-full">
            <label>Response Body</label>
            <textarea className="w-full" rows={40} value={JSON.stringify(JSON.parse(state.responseBody), null, 4)}/>
          </div>
        </Modal>
        <Modal
          header="Consultar chassi na api do renave"
          width={60}
          onHide={() => this.setState({ visibleConsultarChassiRenave: false })}
          visible={state.visibleConsultarChassiRenave}
        >
          <div className="formgrid grid">
            <div className='field col-10'>
              <TextInput
                label="Chassi"
                value={state.Chassi}
                className={'inputfield w-full'}
                onChange={(e) => this.setState({ Chassi: e.target.value })}
              />
            </div>
            <div className='field col-2 flex align-items-end'>

              <Button
                label="Consultar"
                className={'p-button w-full'}
                onClick={async () => {
                  let dados = await EstoqueRenaveService.getByChassi(state.Chassi)

                  this.setState({ retornoApiRenave: JSON.stringify(dados) })
                }}
              />
            </div>
            <div className="field col-12">
              <TextAreaInput
                label="Resposta da consulta do renave"
                className={'inputfield w-full'}
                value={state.retornoApiRenave}
              />
            </div>
          </div>
        </Modal>
      </Page>
    )
  }
}

export default ConsultaChassi
