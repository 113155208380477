import React, { useState } from 'react'
import './_dropdownButton.scss'
import DropdownItem from './DropdownItem'
import { Button } from 'primereact/button'
import OutsideAlerter from '../../utils/OutsideAlerter'

const DropdownMenu = (props) => {
  const [open, setOpen] = useState(false)

  const dropdownItems = props.items ? props.items.map((item, idx) => {
    return (
      <DropdownItem
        first={idx === 0 ? true : false}
        label={item.label}
        onClick={() => {
          if (props.data) {
            item.command(props.data)
          } else {
            item.command()
          }
          setOpen(false)
        }}
        key={idx}
      />
    )
  }) : ''

  return (
    <>
      {open && (
        <OutsideAlerter command={() => {
          if (open === true) {
            setOpen(false)
          }
        }}>
          <div className="dropdown" id="dropdown">
            {dropdownItems}
          </div>
        </OutsideAlerter>
      )}
      <Button
        onClick={() => setOpen(!open)}
        label={props.label}
        icon={props.icon}
        className={`dropdown-button ${props.onlyIcon === undefined ? '' : 'only-icon'} ${props.className}`}
      />
    </>
  )
}

export default DropdownMenu
