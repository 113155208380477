import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { classNames } from 'primereact/utils'
import React from 'react'
import List from '../../../classes/List'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import DataList from '../../../components/utils/DataList'
import { getEmpresaDTO } from '../../../dtos/cadastro/EmpresaDTO'
import CommonHelper from '../../../helpers/CommonHelper'
import { formatDate } from '../../../helpers/formaters'
import EmpresaService from '../../../services/cadastro/EmpresaService'

export default class EmpresaList extends List {
  constructor (props) {
    super(
      props,
      getEmpresaDTO,
      EmpresaService,
      'empresa',
      'codigo_emp',
      'ativas'
    )

    this.state = {
      ...this.state
    }

    this.setColumns([
      {
        key: 'codigo_emp',
        component: (
          <Column
            className="p-col-1"
            field="codigo_emp"
            header="Código"
            sortable
            headerClassName="codigo_emp" />
        )
      },
      {
        key: 'nomraz_emp',
        component: (
          <Column
            className="p-col-4"
            field="nomraz_emp"
            header="Nome Razão"
            sortable
            headerClassName="nomraz_emp" />
        )
      },
      {
        key: 'nomfan_emp',
        component: (
          <Column
            className="p-col-4"
            field="nomfan_emp"
            header="Nome Fantasia"
            sortable
            headerClassName="nomfan_emp" />
        )
      },
      {
        key: 'cnpj_emp',
        component: (
          <Column
            className="p-col-2"
            field="cnpj_emp"
            body={(data) => CommonHelper.formatCnpj(data.cnpj_emp)}
            header="CNPJ"
            sortable
            headerClassName="cnpj_emp" />
        )
      },
      {
        key: 'cerval_emp',
        component: (
          <Column
            className="p-col-1"
            field="cerval_emp"
            body={(data) => data.cerval_emp ? formatDate(data.cerval_emp) : ''}
            header="Vencimento do certificado" />
        )
      },
      {
        key: 'cerkey_emp',
        component: (
          <Column
            className="p-col-1"
            field="cerkey_emp"
            body={(data) => {
              return (
                <span className={'colored-label ' + (data.cerval_emp && (data.cerval_emp.substring(0, 10) > CommonHelper.todayToAmerican()) ? 'green' : 'red')}>
                  {(data.cerkey_emp && data.cerpem_emp)
                    ? (data.cerval_emp.substring(0, 10) > CommonHelper.todayToAmerican()
                      ? 'Certificado importado'
                      : 'Certificado vencido')
                    : 'Certificado não importado'}
                </span>
              )
            }}
            header="Certificado" />
        )
      }
    ])
  }

  render () {
    const { rows, totalRecords, first, page, list, selected } = this.state

    const onSelectOption = (option) => {
      let { filter } = this.state
      this.setState({ opcaoSelecionada: option })

      switch (option) {
      case 'ativas':
        filter.ativo_emp = 'ativa'
        break
      case 'inativas':
        filter.ativo_emp = 'inativa'
        break
      }

      // Se for usuário sances
      // if (CommonHelper.isUsuarioAdmin(infoRecebida.email)) {
      //   filter.ignorarGrupo = true
      // }

      this.setState({ filter }, this.onFilter)
    }

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Empresas</h4>
          <div className="page-header-buttons">
            <Button
              className="mr-2"
              label={CommonHelper.isDesktop() && 'Novo'}
              icon="pi pi-plus"
              onClick={this.onNew}
            />
          </div>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: this.state.opcaoSelecionada === 'ativas' }, 'filter-option')}
              onClick={() => onSelectOption('ativas')}>
              <span className="option-label">Ativas</span>
              <div className="option-quantity">
                {this.state.quantidades?.quantidadeAtivas}
              </div>
            </div>
            <div className={classNames({ active: this.state.opcaoSelecionada === 'inativas' }, 'filter-option')}
              onClick={() => onSelectOption('inativas')}>
              <span className="option-label">Inativas</span>
              <div className="option-quantity">
                {this.state.quantidades?.quantidadeInativas}
              </div>
            </div>
          </div>
        </div>
        <Container>
          <DataList
            data={list}
            selected={selected}
            rows={rows}
            onSelect={this.onSelect}
            totalRecords={totalRecords}
            first={first}
            onPage={this.onPageChange}
            page={page}
            onRowSelect={(e) => this.onView(e.data)}
            showFilter
            filterPlaceholder="Procurar empresas"
            filterName="descricaoFiltro"
            responsive
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
          >
            {this.columns.map((column) => column.component)}
          </DataList>
        </Container>
      </Page>
    )
  }
}
