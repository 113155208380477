import { FileUpload } from 'primereact/fileupload'
import React, { useState } from 'react'
import Modal from '../../../../components/utils/Modal'
import XmlConverter from 'x2js'
import { Button } from 'primereact/button'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../../../../components/utils/Message'
import NotaFiscalService from '../../../../services/veiculosUsados/NotaFiscalService'
import TextInput from '../../../../components/inputs/TextInput'

function EnviarNotaFiscalModal ({ visible, onHide, veiculo, evento }) {
  const [chaveAcesso, setChaveAcesso] = useState('')

  function hideModal () {
    setChaveAcesso('')
    onHide()
  }

  const enviarNotaFiscal = async () => {
    try {
      if (!chaveAcesso) {
        showWarnMessage('Informe uma chave de acesso!')
        return
      }

      await NotaFiscalService.enviarNotaFiscal(chaveAcesso, evento, veiculo.id)

      hideModal()
      showSuccessMessage('Chave da nota fiscal enviada!')
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao tentar enviar a nota fiscal!')
    }
  }

  async function handleUploadChaveXml (event) {
    const file = event.files[0]

    if (file) {
      try {
        const reader = new FileReader()

        reader.addEventListener('load', readFile)

        reader.readAsText(file)

        event.options.clear()
      } catch (error) {
        showErrorMessage(error.message || 'Ocorreu um erro ao tentar ler o arquivo')
      }
    }
  }

  function readFile (event) {
    const xmlConverter = new XmlConverter()
    const xmlObject = xmlConverter.xml2js(event.target.result)

    if (!xmlObject?.nfeProc?.NFe?.infNFe?._Id) {
      showErrorMessage('Arquivo inválido!')
      return
    }

    showSuccessMessage('Chave importada com sucesso!')

    const chaveNFe = xmlObject.nfeProc.NFe.infNFe._Id.replace('NFe', '')

    setChaveAcesso(chaveNFe)
  }

  return (
    <Modal
      visible={visible}
      onHide={hideModal}
      header="Enviar nota fiscal"
      footer={
        <Button
          label="Enviar nota fiscal"
          onClick={() => enviarNotaFiscal()}
        />
      }
    >
      <div className="formgrid grid">
        <div className="field col-12 md:col-8">
          <TextInput
            label="Chave de acesso"
            className="inputfield w-full"
            value={chaveAcesso}
            onChange={(e) => setChaveAcesso(e.target.value)}
          />
        </div>
        <div className="field col-12 md:col-4 flex justify-content-end align-items-end">
          <FileUpload
            chooseLabel="XML da nota fiscal"
            className="mb-1"
            mode="basic"
            accept="text/xml"
            auto
            customUpload
            uploadHandler={handleUploadChaveXml}
          />
        </div>
      </div>
    </Modal>
  )
}

export default EnviarNotaFiscalModal
